import React from "react"
import BlogLayout from "../components/blogLayout"
import { Helmet } from "react-helmet"
import { graphql, Link } from "gatsby"
import Content, { HTMLContent } from "../components/Content"
import "./blog.css"
import { setArchiveMonths } from "../utils"
import { clean } from "diacritic"
import _ from "lodash"

export const BlogTemplate = ({
  content,
  contentComponent,
  description,
  category,
  title,
  date,
  helmet,
}) => {
  const PostContent = contentComponent || Content
  return (
    <>
      <section>
        {helmet || ""}
        <article className="content-container">
          <h2 className="title">{title}</h2>
          <span className="date">{setArchiveMonths(date)}</span>
          <p className="description">{description}</p>
          <PostContent content={content} className="content" />
        </article>
        {category && category.length ? (
          <div className="category-bottom">
            <h4>Kategorie</h4>
            <ul className="category-list">
              {category.map(c => (
                <li key={c + `kategorie`}>
                  <Link to={`/blog/kategorie/${_.kebabCase(clean(c))}`}>
                    {c}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </section>
    </>
  )
}

const BlogPost = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark

  return (
    <BlogLayout
      seoTitle={frontmatter.title}
      hideSidebars={true}
      location={location}
    >
      <BlogTemplate
        content={html}
        contentComponent={HTMLContent}
        description={frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${frontmatter.title}`}</title>
            <meta name="description" content={`${frontmatter.description}`} />
          </Helmet>
        }
        date={frontmatter.date}
        category={frontmatter.category}
        title={frontmatter.title}
      />
    </BlogLayout>
  )
}

export default BlogPost

export const BlogPageQuery = graphql`
  query BlogPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        description
        category
        title
        date(formatString: "DD MM-YYYY")
      }
    }
  }
`
